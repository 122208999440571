const financialDetailsData = [
    {
        label: "Sim",
        value: true
    },
    {
        label: "Não se aplica",
        value: false
    }
];

export default financialDetailsData;